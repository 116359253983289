Cookies = require 'js-cookie'

class CookieManager
  cookie_same_site='lax'
  accept_value='yes'
  reject_value='no'
  cookie_expiration=365 # 1 year
  initialCookieDecisionDone=false
  initialCookieDecisionCookieName='initial_cookie_decision_done'

  constructor: ->
    @initialCookieDecisionDone = @isCookieAccepted(initialCookieDecisionCookieName)
    return

  readConfig: (config) ->
    @config = config

  isCookieAccepted: (cookieKey) ->
    Cookies.get(cookieKey) == accept_value

  editableCookies: ->
    @config.filter (d) -> d.html_options && !d.html_options.disabled

  allowAllCookies: =>
    @editableCookies().forEach (d) => @acceptCookie(d.name)
    @acceptCookie(initialCookieDecisionCookieName)

  disallowAllCookies: =>
    @editableCookies().forEach (d) => @rejectCookie(d.name)
    @acceptCookie(initialCookieDecisionCookieName)

  allowCookieByName: (cookieName) =>
    @acceptCookie(cookieName)
    @acceptCookie(initialCookieDecisionCookieName)

  disallowCookieByName: (cookieName) =>
    @rejectCookie(cookieName)
    @acceptCookie(initialCookieDecisionCookieName)

  acceptCookie: (cookieName) =>
    Cookies.set(cookieName, accept_value, expires: cookie_expiration, sameSite: cookie_same_site)
    window.gtagManager.updateConsentByCookieName(cookieName, true) if typeof window.dataLayer isnt 'undefined'

  rejectCookie: (cookieName) =>
    Cookies.set(cookieName, reject_value, expires: cookie_expiration, sameSite: cookie_same_site)
    window.gtagManager.updateConsentByCookieName(cookieName, false) if typeof window.dataLayer isnt 'undefined'

window.cookieManager = new CookieManager()

$(document).on('turbolinks:load', () ->
  allow_all_cookies = (evt) ->
    evt.preventDefault() unless !evt
    window.cookieManager.allowAllCookies()
    window.cookieManager.editableCookies().forEach (d) ->
      $('.cookie_bar input[name=\''+ d.name + '\']').prop("checked", true);
    $('.cookie_bar').fadeOut()
    return

  allow_selected_cookies = (evt) ->
    evt.preventDefault() unless !evt
    Array.from($(".cookie_bar [type='checkbox']")).forEach (d) ->
      if d.checked
        window.cookieManager.allowCookieByName(d.name)
      else
        window.cookieManager.disallowCookieByName(d.name)
    $('.cookie_bar').fadeOut()
    return

  reject_all_cookies = (evt) ->
    evt.preventDefault() unless !evt
    window.cookieManager.disallowAllCookies()
    window.cookieManager.editableCookies().forEach (d) ->
      $('.cookie_bar input[name=\''+ d.name + '\']').prop("checked", false );
    $('.cookie_bar').fadeOut()
    return

  $('.cookie_bar > a.cookie-accept-all').click allow_all_cookies
  $('.cookie_bar > a.cookie-reject-all').click reject_all_cookies
  $('.cookie_bar > a.cookie-accept-selected').click allow_selected_cookies

  $('.cookie-hint-container a').click (evt) ->
    evt.preventDefault() unless !evt
    $('.cookie_bar').fadeIn()
    return
)
